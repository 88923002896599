<template>
  <div class="container">
    <el-image :src="url"></el-image>


  </div>
</template>

<script>
  export default {
    name: "CooperationIndex",
    data() {
      return {
        url: require('../../assets/images/undev.png')
      }
    }
  }
</script>

<style scoped>
  .container {
    width: 1200px;
    margin: auto;
  }
</style>
